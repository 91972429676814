import React, { useState } from 'react';
import { Connection, PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import * as buffer from 'buffer';
window.Buffer = buffer.Buffer;

const NETWORK = 'https://api.devnet.solana.com';
const RECIPIENT_WALLET = 'GU3XjmNm95hgXLMuwbPwwQ3WWTVUTrTgKZPnzxxD2eAr'; // Replace with your recipient address
const GRID_SIZE = 100;
const COLORS = ['red', 'green', 'blue'];

const createTransaction = async (publicKey, x, y, color) => {
    const connection = new Connection(NETWORK);
    const recipient = new PublicKey(RECIPIENT_WALLET);
    const lamports = 0.1 * 10 ** 9; // 0.1 SOL in lamports
    const memoMessage = `x:${x},y:${y},color:${color}`;

    const transaction = new Transaction().add(
        SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: recipient,
            lamports,
        }),
        {
            keys: [{ pubkey: publicKey, isSigner: true, isWritable: true }],
            programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
            data: Buffer.from(memoMessage),
        }
    );

    const { blockhash } = await connection.getRecentBlockhash();
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    return transaction;
};

const GridPageOld = () => {
    const [selectedSquare, setSelectedSquare] = useState(null);
    const [selectedColor, setSelectedColor] = useState('');

    const handleSquareClick = (x, y) => {
        setSelectedSquare({ x, y });
        setSelectedColor('');
    };

    const handleConfirm = async () => {
        if (!selectedSquare || !selectedColor) return;
        try {
            if (window.solana && window.solana.isPhantom) {
                await window.solana.connect();
                const publicKey = window.solana.publicKey;
                const { x, y } = selectedSquare;

                const transaction = await createTransaction(publicKey, x, y, selectedColor);
                const signedTransaction = await window.solana.signTransaction(transaction);

                const connection = new Connection(NETWORK);
                const signature = await connection.sendRawTransaction(signedTransaction.serialize());

                console.log('Transaction signature:', signature);
                setSelectedSquare(null); // Clear selection after confirming
            } else {
                alert('Phantom wallet is not available. Please install it from https://phantom.app/');
            }
        } catch (error) {
            console.error('Transaction failed:', error);
        }
    };

    const getSquareStyle = (x, y) => {
        return {
            width: '20px',
            height: '20px',
            margin: '1px',
            display: 'inline-block',
            backgroundColor: selectedSquare && selectedSquare.x === x && selectedSquare.y === y ? 'grey' : 'white',
            border: '1px solid black',
        };
    };

    const renderGrid = () => {
        const grid = [];
        for (let x = 0; x < GRID_SIZE; x++) {
            for (let y = 0; y < GRID_SIZE; y++) {
                grid.push(
                    <div
                        key={`${x}-${y}`}
                        style={getSquareStyle(x, y)}
                        onClick={() => handleSquareClick(x, y)}
                    />
                );
            }
        }
        return grid;
    };

    return (
        <div>
            <h1>Choose a Square</h1>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: 'max-content' }}>
                {renderGrid()}
            </div>
            {selectedSquare && (
                <div>
                    <h3>Select a Color for ({selectedSquare.x}, {selectedSquare.y})</h3>
                    <div>
                        {COLORS.map((color) => (
                            <button
                                key={color}
                                style={{
                                    backgroundColor: color,
                                    margin: '5px',
                                    width: '50px',
                                    height: '30px',
                                }}
                                onClick={() => setSelectedColor(color)}
                            >
                                {color}
                            </button>
                        ))}
                    </div>
                    <button onClick={handleConfirm} disabled={!selectedColor}>
                        Confirm
                    </button>
                </div>
            )}
        </div>
    );
};

export default GridPageOld;
