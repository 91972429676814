import React from 'react';
import PhantomTransactionButton from './PhantomTransactionButton';
import GridPageOld from "./GridPageOld";
import NewGridPage from "./GridPage";
import GridPage from "./GridPage";

const App = () => {
    return (
        <div
            style={{
                backgroundImage: "url('background.jpg')",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                minHeight: '100vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
                boxSizing: 'border-box',
            }}
        >
            <div className="container mx-auto bg-white p-4 rounded shadow-md">
                <GridPage/>
            </div>
        </div>
    );
};

export default App;
