import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { Connection, PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import { SketchPicker } from 'react-color';
import { Tooltip } from 'react-tooltip';

const NETWORK = 'https://rpc.ankr.com/solana/b7292e1532f31bcb7004f504512a868b38d755bf6481e8722f047a4908cbd85d';
const RECIPIENT_WALLET = 'GU3XjmNm95hgXLMuwbPwwQ3WWTVUTrTgKZPnzxxD2eAr'; // Replace with your recipient address
const GRID_SIZE = 100;
const MAX_SQUARES = 40;

const firebaseConfig = {
    apiKey: 'AIzaSyASzb6M-gBBO4v84VHLVgl5AHsGSYzxQ4',
    authDomain: 'memecoinwall.firebaseapp.com',
    projectId: 'memecoinwall',
    storageBucket: 'memecoinwall.appspot.com',
    messagingSenderId: '813077126013',
    appId: '1:813077126013:web:f6997bad73f05623eb8a14',
    measurementId: 'G-TXMFXGE9WM'
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const createTransaction = async (publicKey, squares, color, websiteURL) => {
    const connection = new Connection(NETWORK);
    const recipient = new PublicKey(RECIPIENT_WALLET);
    const lamportsPerSquare = 0.0001 * 10 ** 9; // 0.1 SOL in lamports

    // Ensure a default website URL is used if none is provided
    const resolvedWebsiteURL = websiteURL || 'https://twitter.com/realPengLoo';

    // Extract only the coordinates from the selected squares
    const coordinates = squares.map(({ x, y }) => ({ x, y }));

    // Create a memo message containing both coordinates and metadata
    const memoMessage = JSON.stringify({
        coordinates,
        color,
        websiteURL: resolvedWebsiteURL
    });

    const transaction = new Transaction().add(
        SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: recipient,
            lamports: lamportsPerSquare * squares.length // Multiply by the number of squares
        }),
        {
            keys: [{ pubkey: publicKey, isSigner: true, isWritable: true }],
            programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
            data: Buffer.from(memoMessage, 'utf-8') // Encode as UTF-8
        }
    );

    // Get the most recent blockhash and set it in the transaction
    const { blockhash } = await connection.getLatestBlockhash();
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    return transaction;
};

const GridPage = () => {
    const [selectedSquares, setSelectedSquares] = useState([]);
    const [selectedColor, setSelectedColor] = useState('#FFFFFF');
    const [websiteURL, setWebsiteURL] = useState('');
    const [coordinates, setCoordinates] = useState([]);
    const [currentCoordinate, setCurrentCoordinate] = useState(null); // To store the current clicked square

    const handleSquareClick = (x, y) => {
        const coordinate = coordinates.find((coord) => coord.x === x && coord.y === y);
        if (coordinate && coordinate.websiteURL) {
            window.open(coordinate.websiteURL, '_blank');
        } else {
            const existingSquare = selectedSquares.find((square) => square.x === x && square.y === y);
            if (existingSquare) {
                setSelectedSquares(selectedSquares.filter((square) => !(square.x === x && square.y === y)));
            } else {
                if (selectedSquares.length >= MAX_SQUARES) {
                    alert(`You can select a maximum of ${MAX_SQUARES} squares.`);
                } else {
                    setSelectedSquares([...selectedSquares, { x, y }]);
                }
            }
        }

        // Update the current clicked square's coordinates
        setCurrentCoordinate({ x, y });
    };

    useEffect(() => {
        async function fetchCoordinates() {
            try {
                const querySnapshot = await getDocs(collection(db, 'Coordinates'));
                const coordinatesData = querySnapshot.docs.map((doc) => {
                    const data = doc.data();
                    const [x, y] = doc.id.split('_').map(Number);
                    return { x, y, color: data.color, websiteURL: data.websiteURL };
                });
                setCoordinates(coordinatesData);
            } catch (error) {
                console.error('Error fetching coordinates:', error);
            }
        }

        fetchCoordinates();
    }, []);

    const handleConfirm = async () => {
        if (!selectedSquares.length || !selectedColor) return;
        try {
            if (window.solana && window.solana.isPhantom) {
                await window.solana.connect();
                const publicKey = window.solana.publicKey;

                const transaction = await createTransaction(publicKey, selectedSquares, selectedColor, websiteURL);
                const signedTransaction = await window.solana.signTransaction(transaction);

                const connection = new Connection(NETWORK);
                const signature = await connection.sendRawTransaction(signedTransaction.serialize());

                console.log('Transaction signature:', signature);
                setSelectedSquares([]); // Clear selection after confirming
                setWebsiteURL(''); // Clear the URL input
            } else {
                alert('Phantom wallet is not available. Please install it from https://phantom.app/');
            }
        } catch (error) {
            console.error('Transaction failed:', error);
        }
    };

    const handleColorChange = (color) => {
        setSelectedColor(color.hex);
    };

    const handleWebsiteChange = (event) => {
        setWebsiteURL(event.target.value);
    };

    const getSquareStyle = (x, y) => {
        const isSelected = selectedSquares.some((square) => square.x === x && square.y === y);
        const coordinate = coordinates.find((coord) => coord.x === x && coord.y === y);
        const backgroundColor = coordinate ? coordinate.color : '#FFFFFF';
        return {
            width: '10px',
            height: '10px',
            border: '1px solid #ccc',
            backgroundColor: isSelected ? '#ccc' : backgroundColor,
        };
    };

    const renderGrid = () => {
        const grid = [];
        for (let x = 0; x < GRID_SIZE; x++) {
            for (let y = 0; y < GRID_SIZE; y++) {
                const coordinate = coordinates.find((coord) => coord.x === x && coord.y === y);
                const tooltip = coordinate && coordinate.websiteURL ? coordinate.websiteURL : '';

                grid.push(
                    <div
                        key={`${x}-${y}`}
                        style={getSquareStyle(x, y)}
                        onClick={() => handleSquareClick(x, y)}
                        data-tooltip-id="tooltip"
                        data-tooltip-content={tooltip}
                    />
                );
            }
        }
        return grid;
    };

    return (
        <div className="bg-gradient-to-r from-sky-500 to-indigo-500 rounded-md">
            <div className="flex flex-col items-center">
                <img src="logotp.png" alt="500x500 Image" width="200px" />
                <div className="bg-orange-300 rounded-md p-4 font-bold">
                    <h1 className="text-2xl mb-4">CA: jDzoEgrW36NHp14assXubqr2UVLu2piYE8EvqujwFBM</h1>
                    <h1 className="text-2xl mb-4">
                        PUMP FUN{' '}
                        <a
                            className="text-blue-500"
                            href="https://pump.fun/jDzoEgrW36NHp14assXubqr2UVLu2piYE8EvqujwFBM"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://pump.fun/jDzoEgrW36NHp14assXubqr2UVLu2piYE8EvqujwFBM
                        </a>
                    </h1>
                    <h1 className="text-2xl mb-4">TICKER: MCW</h1>
                    <h1 className="text-2xl mb-4">
                        TG:{' '}
                        <a className="text-blue-500" href="https://t.me/coinwallxyz" target="_blank" rel="noopener noreferrer">
                            https://t.me/coinwallxyz
                        </a>
                    </h1>
                    <div>This pixel wall allows users to own a part of the MemeCoinWall by purchasing pixels.</div>
                    <br/>
                    <div>Each pixel can be customized with a URL and a color, creating a unique community-driven mosaic of ever evolving links and colors.</div>
                    <br/>
                    <div>Every purchase will go directly to the pool and burned.</div>
                </div>
                <br />
                <div className="font-bold text-center">
                    <h1 className="text-2xl">Choose Squares and scroll down to Buy</h1>
                    <h4>Price per pixel: 0.0001 SOL</h4>
                </div>
                <br />

                {currentCoordinate && (
                    <div className="text-center mb-4 bg-white rounded-md p-2 border-2 border-blue-500">
                        <h2 className="text-lg font-bold">
                            Current Square: ({currentCoordinate.x}, {currentCoordinate.y})
                        </h2>
                    </div>
                )}

                <div className={`grid grid-cols-100 gap-0`}>{renderGrid()}</div>
                {selectedSquares.length > 0 && (
                    <div className="mt-4">
                        <h3 className="text-lg mb-2">
                            Select a Color for {selectedSquares.length} Squares
                        </h3>
                        <SketchPicker color={selectedColor} onChangeComplete={handleColorChange} />
                        <input
                            type="text"
                            placeholder="Enter Website URL"
                            value={websiteURL}
                            onChange={handleWebsiteChange}
                            className="mt-2 px-2 py-1 border border-gray-300"
                        />
                        <button
                            className={`mt-4 px-4 py-2 bg-blue-500 text-white ${!selectedColor ? 'opacity-50' : ''}`}
                            onClick={handleConfirm}
                            disabled={!selectedColor}
                        >
                            Confirm
                        </button>
                    </div>
                )}
                <br />
                <br />
                <br />
                <br />
            </div>
            <Tooltip id="tooltip" place="top" type="dark" effect="solid" delayShow={0} />
        </div>
    );
};

export default GridPage;
