import React from 'react';
import { Connection, PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import * as buffer from 'buffer';
window.Buffer = buffer.Buffer;

// Constants
const NETWORK = 'https://api.devnet.solana.com';
const RECIPIENT_WALLET = 'GU3XjmNm95hgXLMuwbPwwQ3WWTVUTrTgKZPnzxxD2eAr'; // Replace with your recipient address

// Function to create a transaction with a memo
const createTransaction = async (publicKey) => {
    const connection = new Connection(NETWORK);
    const recipient = new PublicKey(RECIPIENT_WALLET);
    const lamports = 0.1 * 10 ** 9; // 0.1 SOL in lamports
    const memoMessage = 'color:red';

    // Create the transfer and memo instructions
    const transaction = new Transaction().add(
        SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: recipient,
            lamports,
        }),
        {
            keys: [{ pubkey: publicKey, isSigner: true, isWritable: true }],
            programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
            data: Buffer.from(memoMessage),
        }
    );

    // Fetch the recent blockhash and assign it to the transaction
    const { blockhash } = await connection.getRecentBlockhash();
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    return transaction;
};

// React Component
const PhantomTransactionButton = () => {
    const handlePhantomTransaction = async () => {
        try {
            // Check if the Phantom provider is available
            if (window.solana && window.solana.isPhantom) {
                // Connect to Phantom wallet
                await window.solana.connect();
                const publicKey = window.solana.publicKey;

                // Create the transaction
                const transaction = await createTransaction(publicKey);

                // Request transaction signature from Phantom
                const signedTransaction = await window.solana.signTransaction(transaction);

                // Send the transaction to the Solana network
                const connection = new Connection(NETWORK);
                const signature = await connection.sendRawTransaction(signedTransaction.serialize());

                console.log('Transaction signature:', signature);
            } else {
                alert('Phantom wallet is not available. Please install it from https://phantom.app/');
            }
        } catch (error) {
            console.error('Transaction failed:', error);
        }
    };

    return (
        <div>
            <button onClick={handlePhantomTransaction}>Send 0.1 SOL with Memo</button>
        </div>
    );
};

export default PhantomTransactionButton;
